import React from "react";

import "./style.scss";

const InfoSection = (props) => {
  return (
    <section
      id={props.id}
      className={`info-section ${
        props.align && "info-section--" + props.align
      } ${props.name && props.name} ${
        props.color && "info-section--" + props.color
      }`}
    >
      <div className="info-section__content">{props.children}</div>
    </section>
  );
};

export default InfoSection;
