import React from "react";
import { Link } from "gatsby";
import Icon from "../icon";

import "./style.scss";

const items = [
  {
    name: "Rooms & Suites",
    description: "Newly renovated, beautifully decorated retreats.",
    button: "View All",
    to: "rooms",
    class: "rooms",
  },
  {
    name: "Amenities",
    description: "Convenient and indulgent services, always within reach.",
    button: "Explore",
    to: "amenities",
    class: "amenities",
  },
  {
    name: "Gallery",
    description: "Glimpse the detail and artistry of The Edward.",
    button: "Browse",
    to: "gallery",
    class: "gallery",
  },
  {
    name: "Private Events",
    description: "Celebrate any occasion in our unique, intimate setting.",
    button: "Learn More",
    to: "#private-events",
    class: "private-events",
  },
];

const Portal = () => {
  return (
    <section className="portal">
      <div className="portal__grid">
        {items.map((item) => (
          <Link to={item.to} className={`portal__item portal__${item.class}`}>
            <div className="portal__text">
              <h2>{item.name}</h2>
              <p>{item.description}</p>
              <div className="portal__link" to={item.to}>
                {item.button}
                <Icon name="chevron-right" />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Portal;
