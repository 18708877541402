import * as React from "react";
import "react-dates/initialize";
import InfoSection from "../components/info-section";
import Hero from "../components/hero";
import Button from "../components/button";
import Layout from "../components/layout";
import Portal from "../components/portal";
import RoomFinder from "../components/room-finder";

import "../styles/global.scss";

const IndexPage = () => {
  return (
    <Layout title="Home">
      <Hero
        title="History Meets Modern Luxury"
        text="Experience the rebirth of an architectural masterpiece on historic Millionaire's Row."
        page="home"
        center
      >
        <div className="container">
          <div className="find-a-room">
            <div className="find-a-room__title">
              Book 100% online. Check in and out, contact free.
            </div>
            <RoomFinder />
          </div>
        </div>
      </Hero>
      <div className="container">
        <Portal />
        <InfoSection title="Our Story" name="our-story" color="brown">
          <h2>Let us reintroduce ourselves</h2>
          <hr />
          <p>
            The Edward is Buffalo’s freshest hotel, revitalizing iconic Delaware
            Avenue. This historic building was built by E.B. Green in 1910 and
            restored in 2019 to offer the best of old-world opulence and modern
            style. We strive for the highest standards to provide our guests
            with an experience unlike any other.
          </p>
        </InfoSection>
        <InfoSection
          title="Private Events"
          name="private-events"
          align="right"
          id="private-events"
          color="green"
        >
          <h2>Private Events</h2>
          <hr />
          <p>
            Host your next event at The Edward and let the atmosphere elevate
            your occasion.
          </p>
          <div className="private-events__info">
            <div className="private-events__item">
              <div className="private-events__title">30</div>
              <div className="private-events__text">Guests</div>
              <div className="private-events__sub-text">(max capacity)</div>
            </div>
            <div className="private-events__item">
              <div className="private-events__title">$100</div>
              <div className="private-events__text">Hourly Rate</div>
              <div className="private-events__sub-text">(3 hour minimum)</div>
            </div>
            <div className="private-events__item">
              <div className="private-events__title">$150</div>
              <div className="private-events__text">Cleaning Fee</div>
              <div className="private-events__sub-text">(non-refundable)</div>
            </div>
          </div>
          <div className="private-events__button">
            <Button type="primary" text="Book Now" to="#contact" />
            <div className="private-events__disclaimer">
              Prices subject to change.
            </div>
          </div>
        </InfoSection>
      </div>
    </Layout>
  );
};

export default IndexPage;
