import React from "react";

import "./style.scss";

const Hero = (props) => {
  return (
    <section className={`hero ${props.center && "hero--center"}`}>
      <div className={`hero__image ${props.page && "hero--" + props.page}`}>
        <div className="container">
          <div className="hero__text">
            <h1 className="hero__title">{props.title}</h1>
            {!props.center && <hr />}
            <div className="hero__sub-title">{props.text}</div>
          </div>
        </div>
      </div>
      {props.children}
    </section>
  );
};

export default Hero;
